











import { ModuleManagerState } from '@/includes/logic/Modules/ModulesManager'

import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

@Component({})
export default class SaveModuleButton extends Vue {

  @Prop({ type: Boolean, default: true }) isValid!: boolean

  @Emit()
  saveModule() {
    return null
  }

  get saveButtonIcon() {
    if (ModuleManagerState) {
      return ModuleManagerState.isCurrentModuleSaved
        ? 'save'
        : 'plus'
    }

    return ''
  }

  get saveButtonTitle() {
    if (ModuleManagerState) {
      return ModuleManagerState.isCurrentModuleSaved
        ? this.$t('chat_config_save_button').toString()
        : this.$t('module_active_card_add_new').toString()
    }

    return ''
  }
}
